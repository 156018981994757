
































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
  components: {

  }
})
export default class buddyaistatistics extends Vue {
  [x: string]: any;
  public form: any = {
    pages: 1, size: 10, current: 1, total: 0, employeeId: '', email: '',
    type:'', description:'', apporscenarioName:'', firstmenuname:'', secondmenuname:'', articlename:'', logTime:''
  };
  public datacreateTime: any[] = [];
  private tableData: any[] = [];
  public typeList: any[] = [
    { id: 0 , name: '查看任务'},
    { id: 1 , name: '查看公司服务热线详情'},
    { id: 2 , name: '阅读新手须知'},
    { id: 3 , name: '我要反馈'},
    { id: 4 , name: '取消收藏文章'},
    { id: 5 , name: '完成任务'},
    { id: 6 , name: '小程序webview中打开'},
    { id: 7 , name: 'AI搜索'},
    { id: 8 , name: '查看KPI'},
    { id: 9 , name: '前往诺华视界看视频'},
    { id: 10 , name: '登录'},
    { id: 11 , name: '收藏文章'},
    { id: 12 , name: '前往外链: Windows密码重置页面'},
    { id: 13 , name: '查看文章'}
  ];
  public loading: Boolean = false;
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  public async created(): Promise<void> {
    await this.getList();
  }
  //获取数据源
  public async getList() {
    var that = this, dataJson = {};
    that.loading = true;
    if (that.datacreateTime && that.datacreateTime.length > 0) {
      that.form.endTime = that.datacreateTime[1]
      that.form.startTime = that.datacreateTime[0]
    } else {
      that.form.endTime = ''
      that.form.startTime = ''
    }
    dataJson = {
      employeeId: that.form.employeeId.toString(),//员工ID
      email: that.form.email,//员工邮箱
      type: that.form.type,//操作类型
      logTimeStart: that.form.startTime,//开始时间
      logTimeEnd: that.form.endTime//结束时间
    }

    const res = await this.statisticsApi.getBuddyAIList(dataJson);
    that.tableData = res.records;
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
    that.loading = false;
  }

  //导出
  public exportClick(row: any, type: string): void {
    var that = this, data_ = {}, str: any = '';
    if (that.tableData.length <= 0) {
      this.$message({
        type: 'error',
        message: '当前无下载内容！'
      })
      return;
    }
    if (that.datacreateTime.length > 0) {
      this.form.endTime = that.datacreateTime[1]
      this.form.startTime = that.datacreateTime[0]
    } else {
      this.form.endTime = ''
      this.form.startTime = ''
    }
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var day = date.getDate();
    var timeSend = year + '-' + month + '-' + day;
    data_ = {
      "employeeId": that.form.employeeId.toString(),//员工ID
      "email": that.form.email,//员工邮箱
      "type": that.form.type,//操作类型
      "logTimeStart": that.form.startTime,//开始时间
      "logTimeEnd": that.form.endTime//结束时间
    }
    that.loading = false;
    this.statisticsApi.BuddyAIDownloadByCondition(data_, '诺小二AI'+timeSend);
  }
  // 导出拼接
  public splicing(data: any) {
    var str = [], strString = '';
    for (var key in data) {
      str.push(key + "=" + data[key])
    }
    strString = str.join('&')
    return strString;
  }


  public search() {
    //插件时间格式化
    if (this.datacreateTime && this.datacreateTime.length > 0) {
      this.form.startTime = this.datacreateTime[0];
      this.form.endTime = this.datacreateTime[1];
    } else {
      this.form.startTime = "";
      this.form.endTime = "";
    }
    this.form.current = 1
    this.getList()
  }
  // 重置
  public reset() {
    this.form = {
      size: this.form.size, current: 1,
      employeeId: '', email: '', type:''
    };
    this.datacreateTime = [];
    this.$refs.singleTable.clearSort();
    this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
}
